import {instance} from '../instance'

/**
 * Gets a list of the users
 * @returns 
 */
export async function getUsers(){
    return await instance.get('/users')
}

export async function createUser(user){
    return await instance.post('/users',user)
}

export async function getUserById(id){
    return await instance.get(`users/${id}`)
}

export async function getUserByUsername(username){
    return await instance.get(`/users/byUsername/${username}`)
}

export async function updateUser(id, user){
    return await instance.patch(`/users/${id}`,user)
}

export async function addToRole(idUser, idRole){
    return await instance.post(`users/${idUser}/${idRole}`)
}