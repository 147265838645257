import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { AppBarMenu } from './components/AppBarMenu';
import Footer from './components/Footer';
import Home from './containers/Home/Home';
import ContactUs from './containers/Contact/ContactUs';
import Personal from './containers/Services/Personal';
import Business from './containers/Services/Business';
import AboutUs from './containers/AboutUs/AboutUs';
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy';
import ScrollButton from './components/ScrollButton'
import { FormLogin, FormSignup, LogOut } from './containers/auth/pages';
import { AdminList } from './containers/AdminList';
import Auth from './components/Auth'
import { AdminClientEditor } from './containers/AdminClientEditor';
import { Dashboard } from './containers/Dashboard/Dashboard';
import { registerLicense } from '@syncfusion/ej2-base'
import { useAuth } from './hooks/useAuth'


import './styles.css';

function App () {

  registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

  const { auth, LogOut } = useAuth();
  const navigate = useNavigate();

  const [pageSelect, setpageSelect] = useState(0);

  const changePage = (page) => {
    setpageSelect(page);
    navigate('/')
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      });
  }
    return (
      <div className='init'>
        <Translation>
          { t => <AppBarMenu changePage={ changePage } t={t} auth={auth} /> }
        </Translation>
        <CSSTransition key={0} timeout={500} unmountOnExit classNames="item" in={pageSelect === 0}>
          <Translation>
            { t => <Home changePage={ changePage } t={t}/> }
          </Translation>
        </CSSTransition>
        <CSSTransition key={1} timeout={500} unmountOnExit classNames="item" in={pageSelect === 1}>
          <Translation>
            { t => <AboutUs changePage={ changePage } t={t}/> }
          </Translation> 
          </CSSTransition>
        <CSSTransition key={2} timeout={500} unmountOnExit classNames="item" in={pageSelect === 2}>
          <Translation>
            { t => <Personal changePage={ changePage } t={t}/> }
          </Translation>
          </CSSTransition>
        <CSSTransition key={3} timeout={500} unmountOnExit classNames="item" in={pageSelect === 3}>
          <Translation>
            { t => <Business changePage={ changePage } t={t}/> }
          </Translation>
          </CSSTransition>
        <CSSTransition key={4} timeout={500} unmountOnExit classNames="item" in={pageSelect === 4}>
          <Translation>
            { t => <ContactUs margin={true} t={t}/> }
          </Translation>
          </CSSTransition>
        <CSSTransition key={5} timeout={800} unmountOnExit classNames="item" in={pageSelect === 5}>
          <Translation>
            { t => <PrivacyPolicy changePage={ changePage } t={t}/> }
          </Translation> 
        </CSSTransition>
        <CSSTransition key={6} timeout={500} unmountOnExit classNames="item" in={pageSelect === 6}>
          <Translation>
            { t => <FormLogin changePage={ changePage } t={t}/> }
          </Translation> 
        </CSSTransition>
        <CSSTransition key={7} timeout={500} unmountOnExit classNames="item" in={pageSelect === 7}>
          <Translation>
            { t => <FormSignup changePage={ changePage } t={t}/> }
          </Translation> 
        </CSSTransition>
        <CSSTransition key={8} timeout={500} unmountOnExit classNames="item" in={pageSelect === 8}>
          <Translation>
            { t => <Dashboard changePage={ changePage } t={t}/> }
          </Translation> 
        </CSSTransition>
        <CSSTransition key={9} timeout={500} unmountOnExit classNames="item" in={pageSelect === 9}>
          <Translation>
            { 
              t => 
              <Routes> 
                <Route path='/' element={<AdminClientEditor changePage={ changePage } t={t}/>} />
                <Route path='/:idUser' element={<AdminClientEditor changePage={ changePage } t={t}/>} />
              </Routes>
            }
          </Translation> 
        </CSSTransition>
        <CSSTransition key={10} timeout={500} unmountOnExit classNames="item" in={pageSelect === 10}>
          <Translation>
            { t => <Home logOut={ LogOut } changePage={ changePage } t={t}/> }
          </Translation> 
        </CSSTransition>
        <Translation>
            { t => <Footer t={t} changePage={ changePage }/> }
        </Translation>
        <ScrollButton />
      </div>
    );

    // return (
    //   <div className='init'>
     //      <Routes>
    //         <Route path='/' element={<FormLogin/>} />
    //         <Route path='/login' element={<FormLogin/>} />
    //         <Route path='/signup' element={<FormSignup/>} />
    //         <Route path='/dashboard' element={<Auth allowedRoles={["User", "Admin"]} />}>
    //           <Route path='' element={<Dashboard />} />
    //         </Route>
    //         <Route path='/user' element={<Auth allowedRoles={["Admin"]} />}>
    //           <Route path="list" element={<AdminList />} />
    //           <Route path="create" element={<AdminClientEditor />} />
    //           <Route path="update/:idUser" element={<AdminClientEditor />} />
    //         </Route>
    //       </Routes>
    //   </div>
    // )
}

export default withTranslation()(App);