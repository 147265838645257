import {instance} from '../instance'

export async function login({username, password}){
    return await instance.post('/auth/login',{username, password})
}

export function getToken(){
    const token = '';
    return token ? token : '';
}

export function logout(){

}