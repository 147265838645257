import React, { Component, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from '../images/f2g_logo_750px.png';
import ChangeLang from './ChangeLang';
import { Menu, MenuItem, Toolbar } from '@mui/material';


export const AppBarMenu = ({ changePage, t, auth }) => {
    
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [pageSelected, setPageSelected] = useState(0)
    const [pages, setPages] = useState([])

    useEffect(() => {
      
        handleChangeAuth()

    }, [auth])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    };
    const handleChangePage = (page) => {

        setPageSelected(page)

        handleCloseNavMenu();
        changePage(page);
    };

    const handleChangeAuth = () => {

        if(!auth)
        {
            setPages([
                { title: 'Home', number: 0 },
                { title: 'About', number: 1 }, 
                { title: 'Personal', number: 2 }, 
                { title: 'Business', number: 3 },
                { title: 'Contact', number: 4 },
                { title: 'LogIn', number: 6 }
            ]);
        }
        else
        {
            setPages([
                { title: 'Home', number: 0 },
                { title: 'About', number: 1 }, 
                { title: 'Personal', number: 2 }, 
                { title: 'Business', number: 3 },
                { title: 'Contact', number: 4 },
                { title: 'Dashboard', number: 8 },
                { title: 'Logout', number: 10 }
            ]);
        }
    };

    return (
        <AppBar color='bar' className='app-bar-menu' >
            <Toolbar>
            <Box 
                sx={{ flexGrow: 1, height: '68px',
                    display: { xs: 'none', md: 'flex', justifyContent: 'space-between', marginRight: '100px' } 
                }}>
                <img src={logo} alt="Logo" className='g-ml-15 g-mr-8 app-bar-logo' onClick={() => changePage(0)}/>
                {pages.map((page) => (
                    <Button
                        style={{fontFamily: 'Montserrat', color:'#607F5C', fontWeight: 'bold'}}
                        key={page.number}
                        onClick={() => changePage(page.number)}
                        sx={{ my: 2, display: 'block' }}
                        >
                        {t(page.title)}
                    </Button>
                ))}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' }}}
                    >
                    {pages.map((page) => (
                        <MenuItem key={page.number}
                            selected={page.number === pageSelected}
                            onClick={() => handleChangePage(page.number)}>
                            <Typography textAlign="center" fontFamily={'Montserrat'}>{t(page.title)}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
                <img src={logo} alt="Logo" className='app-bar-logo' height={40} onClick={() => changePage(0)} />
            </Box>
            <ChangeLang t={t} />
                    {/* <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <AccountCircle />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{marginLeft: 10}}>
                            {t("LogIn")}
                        </Typography>
                    </IconButton> */}
                </Toolbar>
            </AppBar>
    );
}