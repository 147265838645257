import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL;

export const instance = axios.create({
    baseURL: BASE_URL,
});

instance.interceptors.request.use(
    (request) => {
        var token = localStorage.getItem('jwt_token')
        request.headers['Authorization'] = token ? `Bearer ${token}` : '';
        return request;
    },
    (error) => error
)