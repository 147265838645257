export function GeneratePassword(){
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    const allChars = "abcdefghijklmnopqrstuvwxyz";
    
    let password = "";
    
    // Generate 1 uppercase letter
    password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
    
    // Generate 1 number
    password += numbers.charAt(Math.floor(Math.random() * numbers.length));
    
    // Generate 1 special character
    password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));
    
    // Generate remaining characters
    for (let i = 0; i < 5; i++) {
        password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }
    
    return password;
}