import {useState, useEffect, useMemo, createContext} from 'react'
import jwt from 'jwt-decode'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext();

export function AuthProvider(props){

    const [user, setUser] = useState(null)
    const [auth, setAuth] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        
        const token = localStorage.getItem('jwt_token')
        if(token)
        {
            setAuth(true)
            var decodeToken = jwt(token)
            const { sub, username, roles } = decodeToken;
            setUser({sub, username, roles})
        }
    }, [])
    
    function LogIn(token){
        localStorage.setItem('jwt_token', token)
        var decodeToken = jwt(token)
        const { sub, username, roles } = decodeToken;
        setUser({sub, username, roles})
        setAuth(true)
    }

    function LogOut(){
        setUser(null)
        setAuth(false)
        localStorage.clear()
    }

    const value = useMemo(() => {
        return({
            user,
            auth,
            LogIn,
            LogOut
        })
    },[user, auth])

    return <AuthContext.Provider value={value} {...props}/>

}