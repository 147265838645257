import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './contexts/auth-context'

import { BrowserRouter } from 'react-router-dom';

import App from './App';
import i18n from "./constants/LangConfig";

import './App.css';

import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Mgo+DSMBaFt/QHNqVVhkW1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9iSXxXd0NiX3pXcn1XRQ==;Mgo+DSMBPh8sVXJ0S0V+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xSdkdnWHlfdnFcQ2JcUA==;ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhXX5YcXJQT2daWEY=;NzQ3MDIxQDMyMzAyZTMzMmUzMFNGYzRwTnA0cVAyRyt3ajJycmt1b0NHZUF3Mmg5MHFaby9CQWlrN1lwdDQ9;NzQ3MDIyQDMyMzAyZTMzMmUzMGZOb0pXOStnMlVTU2NocmxHTWRxa0QwQUN6a2RwVGJqWmdMbjN5ZWpDK3M9;NRAiBiAaIQQuGjN/V0Z+X09EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdERjW3tfdnVSRmBcVkJ0;NzQ3MDI0QDMyMzAyZTMzMmUzMEYrWWROd3lkcjA0RkswTUQvQ05XRGpzY3l4QzRDbEtWZmFobnlyU2d3eXM9;NzQ3MDI1QDMyMzAyZTMzMmUzMG9kZXdaWGh1V1o5YWJtcXNKWGM0bFRROG4rMGdzR2VtRmh0WTVLamZtbjA9;Mgo+DSMBMAY9C3t2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhXX5YcXNVRmFaWUY=;NzQ3MDI3QDMyMzAyZTMzMmUzMG9tcEFjUll3L0QyVHNNMktpSmRDL0FkNzZFSDlQeHBrd3dlTzVZTVZSOFk9;NzQ3MDI4QDMyMzAyZTMzMmUzMG9NUGlpQjlpOXJWbUx6VGdtbmFSSHNCdDg3V0creW9FbkZBWmFzcUo1V0U9;NzQ3MDI5QDMyMzAyZTMzMmUzMEYrWWROd3lkcjA0RkswTUQvQ05XRGpzY3l4QzRDbEtWZmFobnlyU2d3eXM9');


const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    mode: 'light',
    primary: { main: '#273E34', contrastText: '#ffffff' },
    secondary: { main: '#607F5C', contrastText: '#ffffff' },
    error: { main: '#AF4141' },
    info: { main: '#ffffff', contrastText: '#273E34' },
    bar: {main: '#A9C3A7', contrastText: '#273E34'},
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

// const renderApp = () => {
//   ReactDOM.render(
//     <ThemeProvider theme={theme}>
//       {/* <ClientDashboard /> */}
//       {/* <AdminClientEditor /> */}
//       {/* <AdminList /> */}
//       <App />
//       {/* <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<FormLogin />} />
//           <Route path="/signup" element={<FormSignup />} />
//           <Route path="/app" element={<App />} />
//         </Routes>
//       </BrowserRouter> */}
//     </ThemeProvider>,
//     document.getElementById('root')
//   );
// }
// renderApp();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter >
        <AuthProvider>
          <App/>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>,
  </React.StrictMode>
)
