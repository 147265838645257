import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Header } from '../../../components/Header';



export const FormSignup = ({ changePage, t }) => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    console.log({name, lastname, email, password  });
    setName('');
    setLastname('');
    setEmail('');
    setPassword('');
  };
  

  const onTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onLastnameChange = (event) => {
    setLastname(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
   <>
     <div className="container">
      <form className="form-signup animate__animated animate__fadeIn" onSubmit={onSubmit}>
        <h2 className="form-title">SIGNUP</h2>
        <div className="form-container-formsignup">
          <div className="input-group">
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={onNameChange}
              required
            /><span className="border"></span>
            <label htmlFor="name">Name</label>
          </div>
          <div className="input-group">
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={lastname}
              onChange={onLastnameChange}
              required
            /><span className="border"></span>
            <label htmlFor="lastname">LastName</label>
          </div>
          <div className="input-group">
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onEmailChange}
              required
            /><span className="border"></span>
            <label htmlFor="email">Email*</label>
          </div>
          <div className="input-group">
            <input
              type={showPassword ? "text" : 
              "password"}
              id="password"
              name="password"
              value={password}
              onChange={onPasswordChange}
              required
            /><span className="border"></span>
            <label htmlFor="password">Password</label>
            <button
                type="button"
                className="see-password"
                onClick={ onTogglePassword }
            >
               {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
            </button>
          </div>
          <button className="button-formsignup" type="submit">
            SEND
          </button>
        </div>
        {/* <p className="paragraph-formsignup">¿You have an account? <span>Log in</span></p> */}
        <p className="paragraph-formsignup">
          ¿You have an account?
          <Link onClick={() => changePage(6)} className='link'>
            Log in
          </Link>
        </p>
      </form>
     </div>
   </>
  );
}