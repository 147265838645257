import { GridComponent, ColumnDirective, ColumnsDirective, Page, Inject, Filter, Group, } from '@syncfusion/ej2-react-grids';
// import data from './dataSource.json';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getUsers } from '../../api/services/UserService'

interface ButtonProps {
    changePage: (page: number) => void;
}

export const AdminListGrid = ({ changePage } : ButtonProps) => {

    var grid: GridComponent | null;
    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        
        const getUsersData = async() =>{
            var response = await getUsers();

            if(response.status === 200)
            {
                setData(response.data.value)
            }
        }

        getUsersData();
    }, [])

    function selectedUser(){
        if (grid) {
            const selectedrecords = grid.getSelectedRecords();
            type UserKey = keyof typeof selectedrecords;
            const userSelected = '0' as UserKey;
            const user = selectedrecords[userSelected];
            type IdKey = keyof typeof user;
            const id = 'id' as IdKey;
            changePage(9)
            navigate(`${user[id]}`);
        }
    }

    function createUser(){
        changePage(9)
    }

    return (
        <div className="grid-container animate__animated animate__fadeIn">
            <GridComponent dataSource={data} 
                allowPaging={true}
                pageSettings={{ pageSize: 10 }}
                allowFiltering={true}
                allowGrouping={true}
                rowSelected={selectedUser}
                ref={g => grid = g}
            >
                <ColumnsDirective >
                    <ColumnDirective field='userName' headerText='Client' textAlign="Left" width='100'/>
                    <ColumnDirective field='initialInvestment' headerText='Initial investment' format="C2" width='90'/>
                    <ColumnDirective field='startDate' headerText='Start date' width='65'/>
                    <ColumnDirective field='termDays' headerText='Term (Days)' width='75'/>
                    <ColumnDirective field='maturityDate' headerText='Maturity date' width='75'/>
                    <ColumnDirective field='maturityAmount' headerText='Maturity amount (MXN)' format="C2"  width='100'/>
                    <ColumnDirective field='returnAmount' headerText='Return (MXN)' format="C2"  width='73'/>
                </ColumnsDirective>
                <Inject services={[Page,  Filter, Group]} />
            </GridComponent>


            <div className="grid-buttons-container">
                <button className="grid-button" type="submit" onClick={createUser}>Add Customer</button>
            </div>
        </div>
    )
}
