import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: "",
  resources: {
    en: {
      translation: {
        Home: "Home",
        LogIn: "Log In",
        About: "About",
        Personal: "Personal",
        Business: "Business",
        Contact: "Contact",
        ButtonLearnMore: "Learn more",
        ButtonContactUs: "Contact Us",
        HomeBoxAboutUsTitle: "ABOUT US",
        HomeBoxAboutUsContent1: "Here to help you grow.",
        HomeBoxAboutUsContent2: "Our company helps individuals, families and businesses achieve their financial goals.",
        HomeBoxAboutUsContent3: "We guide our clients to success through our highly professional and personalised consultancy and management service. We pride ourselves on providing only the best quality and honest advice we can.",
        HomeBoxServicesTitle: "SERVICES",
        HomeBoxServicesTitlePersonal: "PERSONAL",
        HomeBoxServicesContentPersonal: "Individuals and Families",
        HomeBoxServicesTitleBusiness: "BUSINESS",
        HomeBoxServicesContentBusiness: "Corporations and Organisations",
        ContactUsTitle: "CONTACT US",
        ContactUsFormName: "Name",
        ContactUsFormEmail: "Email",
        ContactUsFormSubject:"Subject",
        ContactUsFormPhone: "Phone number",
        ContactUsFormMessage: "Message",
        ContactUsFormMessagePlaceholder: "Type your message",
        ContactUsFormButton: "Submit",
        PersonalTitle: "PERSONAL",
        PersonalSubtitle: "Asset & Wealth Management Services for individuals and families.",
        PersonalBoxInfoTitleInvestments: "INVESTMENTS & SAVING SOLUTIONS",
        PersonalBoxInfoContentInvestments: "If you’re looking to make wise investment decisions, Flow2Grow should be your first point of call. We will carefully guide you through our investment consultancy process, from beginning to end. Starting with a full assessment of your current situation, we will take this information, carefully analyse it and then produce an in-depth report. The next stage is then to help you formulate, and implement a strategy, utilising our wealth of knowledge and network of partners. We are brokers for many of the world´s leading banks and financial institutions.",
        PersonalBoxInfoTitleRetirement: "RETIREMENT & ESTATE PLANNING",
        PersonalBoxInfoContentRetirement: "People often talk of retirement, and the goals and dreams they have in mind, but rarely do we hear of how they plan to get to that point financially. ´Retirement´ does not necessarily have to mean a time when we stop working all together, it can be a point in time where we can simply choose to work or not, as we are already financial independent. Our work at Flow2Grow is to guide you and your family all of the way to this point.",
        PersonalBoxInfoTitleOther: "OTHER SERVICES",
        PersonalBoxInfoContentOther: "Over the years we have forged strong partnerships with companies in other sectors in order to be able to provide the most complete possible service to our clients. We work with a network of lawyers, accountants and tax advisers, all of the highest quality and reputation.",
        BusinessTitle: "BUSINESS",
        BusinessSubtitle: "Services for companies and organisations.",
        BusinessBoxInfoTitleInvestments: "INVESTMENTS & FINANCE",
        BusinessBoxInfoContentInvestments: "Flow2Grow are leading experts in the field of business finance and investment, drawing upon over 15 years of experience. Some of our specialties include:",
        BusinessBoxInfoContentInvestmentsItem1: "Financial models, planning and structuring",
        BusinessBoxInfoContentInvestmentsItem2: "Investment, banking and financing solutions",
        BusinessBoxInfoContentInvestmentsItem3: "Private equity",
        BusinessBoxInfoContentInvestmentsItem4: "Tax efficiency",
        BusinessBoxInfoContentInvestmentsItem5: "Employee pensions and benefits",
        BusinessBoxInfoTitleEnterprise: "BUSINESS & ENTERPRISE SUPPORT",
        BusinessBoxInfoContentEnterprise: "The team at Flow2Grow are all passionate about business and enterprise, meaning our clients receive the highest quality of service, from people that really care. Some of the ways on which we help clients with their businesses are:",
        BusinessBoxInfoContentEnterpriseItem1: "Start-up advice & business planning",
        BusinessBoxInfoContentEnterpriseItem2: "Feasibility studies",
        BusinessBoxInfoContentEnterpriseItem3: "Project management",
        BusinessBoxInfoContentEnterpriseItem4: "Corporate strategy",
        BusinessBoxInfoContentEnterpriseItem5: "Business consultancy services",
        PrivacyPolicy: "Privacy Policy",
        PrivacyPolicyBoxInfoContent1: "Flow2Grow is concerned about your right to privacy. When you visit our website, please know that the following data is collected:",
        PrivacyPolicyBoxInfoContentItem1: "The type of browser used",
        PrivacyPolicyBoxInfoContentItem2: "Geographical region the connection is originating from",
        PrivacyPolicyBoxInfoContentItem3: "Date and time of the connection",
        PrivacyPolicyBoxInfoContentItem4: "Pages visited during the connection",
        PrivacyPolicyBoxInfoContentItem5: "Date and time the connection was severed",
        PrivacyPolicyBoxInfoContent2: "This information is used solely to determine traffic patterns on the site. No personal information is collected at any time. Any personal correspondence to us that originates from this site via one of the email links will be for internal use only and treated confidentially. We do not disclose personal information to any third-party without consent. If you have questions or concerns about our policy, please contact us at (+52) 55 6790 6946 during normal business hours or by email at info@flow2grow.com.",
        PrivacyPolicyBoxInfoContent3: "Disclaimer",
        PrivacyPolicyBoxInfoContent4: "This website is for informational purposes only and does not constitute a complete description of our investment advisory services or any past performance. This website is neither a solicitation nor an offer to sell securities or investment advisory services except where we are appropriately registered or exempt from such registration. Information throughout this site, whether stock quotes, charts, articles, or any other statement or statements regarding market or other financial information, is obtained from sources which we and our suppliers believe to be reliable. ",
        PrivacyPolicyBoxInfoContent5: "However, we do not warrant or guarantee the timeliness or accuracy of this information. Nothing on this website should be interpreted to state or imply that past results are any indication of future performance. There are no warranties, expressed or implied, as to accuracy, completeness, or results obtained from any information posted on this or any 'linked' website.",
        AboutUsTitle: "MORE ABOUT US",
        AboutUsContent: "Let's grow!",
        AboutUsContentInfo1: "Flow2Grow is a financial and business consultancy firm, benefiting from over 15 years of experience. We have a diverse range of services that we provide to clients, some of our specialties include:",
        AboutUsContentInfoItem1: "Investments and Savings",
        AboutUsContentInfoItem2: "Asset Management",
        AboutUsContentInfoItem3: "Retirement and Estate Planning",
        AboutUsContentInfoItem4: "Business planning, strategy and guidance",
        AboutUsContentInfo2: "Our business philosophy is simple… to provide the highest possible level of service to our clients. We achieve this by creating and nurturing positive relationships. We are able to do this, as we have a passion for what we do, the rest comes naturally.",
        AboutUsContentInfo3: "Flow2Grow as a company strive to:",
        AboutUsContentInfoItem5: "Show respect for everyone that surrounds us",
        AboutUsContentInfoItem6: "Minimise any negative impact on our environment and maximise the positive",
        AboutUsContentInfoItem7: "Create opportunities where we see them",
        AboutUsContentInfoItem8: "Constantly learn, innovate and improve where we can",
        AboutUsContentInfoItem9: "Remain humble and open minded at all times",
        AboutUsTestimonialsTitle: "TESTIMONIALS",
        AboutUsContentTestimonial1: "“Absolutely first class service. I highly recommend Flow2Grow for genuine, honest and straight forward advice. Extremely knowledgeable, and most of all, really nice to deal with.”",
        AboutUsContentTestimonial2: "\"I was looking for someone to help me with my finances and investments and was introduced to Simon by good friend of mine. He took his time to understand exactly what my current situation was, and what I was looking to achieve. We came up with a great plan, and I’ve been very happy with the results. I also consider Simon a friend, we have got on well since we first met.\"",
        AboutUsContentTestimonial3: "“Fantastic advise from someone who took the time to understand what I wanted, and then gave me a range of options based on my specific needs. Very professional service from the start which continues to this day.”",
        AboutUsAuthorTestimonial1: "Dr. A. Lopez",
        AboutUsAuthorTestimonial2: "M. Diletti",
        AboutUsAuthorTestimonial3: "C.Camhaji",
        AboutUsButtonHere: "Click here to set up a meeting"
      }
    },
    es: {
      translation: {
        Home: "Inicio",
        LogIn: "Iniciar sesión",
        About: "Acerca de",
        Personal: "Personal",
        Business: "Negocios",
        Contact: "Contacto",
        ButtonLearnMore: "Ver más",
        ButtonContactUs: "Contacto",
        HomeBoxAboutUsTitle: "ACERCA DE",
        HomeBoxAboutUsContent1: "Estamos aquí para ayudarte a crecer.",
        HomeBoxAboutUsContent2: "La misión de Flow2Grow es ayudar a individuos, familias y empresas a alcanzar sus objetivos financieros.",
        HomeBoxAboutUsContent3: "Hacemos todo lo posible para guiar a nuestros clientes hacia el éxito, a través de nuestro servicio de consultoría y gestión altamente profesional y personalizado. Nos enorgullecemos de brindar un servicio de calidad y un asesoramiento completamente honesto.",
        HomeBoxServicesTitle: "SERVICIOS",
        HomeBoxServicesTitlePersonal: "PERSONAL",
        HomeBoxServicesContentPersonal: "Individuos y Familias",
        HomeBoxServicesTitleBusiness: "NEGOCIOS",
        HomeBoxServicesContentBusiness: "Corporaciones y Organizaciones",
        ContactUsTitle: "CONTACTO",
        ContactUsFormName: "Nombre",
        ContactUsFormEmail: "Correo",
        ContactUsFormSubject:"Asunto",
        ContactUsFormPhone: "Número de teléfono",
        ContactUsFormMessage: "Mensaje",
        ContactUsFormMessagePlaceholder: "Escribe tu mensaje aquí...",
        ContactUsFormButton: "Enviar",
        PersonalTitle: "PERSONAL",
        PersonalSubtitle: "Servicios de gestión de activos y patrimonios para individuos y familias",
        PersonalBoxInfoTitleInvestments: "INVERSIONES Y PLANES DE AHORRO",
        PersonalBoxInfoContentInvestments: "Si estás buscando tomar decisiones acertadas de inversión, Flow2Grow podría ser su primer punto de referencia. Te guiaremos cuidadosamente a través de nuestro proceso de consultoría de inversión, de principio a fin. Comenzaremos con una evaluación completa de tu situación actual, tomaremos esta información, la analizaremos cuidadosamente y luego produciremos un informe detallado. La siguiente etapa es ayudarte a formular e implementar una estrategia, utilizando nuestros conocimientos y nuestra red de socios. Somos corredores para muchos de los principales bancos e instituciones financieras del mundo.",
        PersonalBoxInfoTitleRetirement: "RETIRO Y PLANIFICACIÓN INMOBILIARIA",
        PersonalBoxInfoContentRetirement: "Las personas a menudo hablan de su retiro y de las metas y sueños que tienen en mente para ese momento, pero rara vez escuchamos de cómo planean llegar a ese punto financieramente. La palabra \"retiro\" no necesariamente tiene que significar el momento en el cual dejamos de trabaja luego de cumplir un periodo de tiempo, puede ser el momento en el que simplemente podemos elegir si continuar trabajando o no, ya que ya somos independientes financieramente. Nuestro trabajo en Flow2Grow es guiar, tanto a ti como a tu familia hasta este punto de independencia.",
        PersonalBoxInfoTitleOther: "OTROS SERVICIOS",
        PersonalBoxInfoContentOther: "A lo largo de los años, hemos forjado relaciones sólidas con empresas de otros sectores, para así poder brindar el servicio más completo posible a nuestros clientes. Trabajamos con una red de abogados, contadores y asesores fiscales, todos de la más alta calidad y reputación.",
        BusinessTitle: "NEGOCIOS",
        BusinessSubtitle: "Servicios para empresas y organizaciones.",
        BusinessBoxInfoTitleInvestments: "INVERSIONES Y FINANZAS",
        BusinessBoxInfoContentInvestments: "Flow2Grow está conformado por expertos líderes en el campo de las finanzas y de la inversión empresarial, con más de 15 años de experiencia. Algunas de nuestras especialidades incluyen:",
        BusinessBoxInfoContentInvestmentsItem1: "Modelos financieros, planificación y estructuración.",
        BusinessBoxInfoContentInvestmentsItem2: "Soluciones de inversión, banca y financiación.",
        BusinessBoxInfoContentInvestmentsItem3: "Capital privado.",
        BusinessBoxInfoContentInvestmentsItem4: "Eficiencia fiscal.",
        BusinessBoxInfoContentInvestmentsItem5: "Pensiones y beneficios para empleados.",
        BusinessBoxInfoTitleEnterprise: "APOYO A EMPRESAS Y NEGOCIOS",
        BusinessBoxInfoContentEnterprise: "El equipo de Flow2Grow es apasionado a los negocios, lo que significa que nuestros clientes reciben la más alta calidad de servicio de parte de personas que realmente se preocupan por ellos. Algunas de las formas en que ayudamos a los clientes con sus negocios son:",
        BusinessBoxInfoContentEnterpriseItem1: "Asesoramiento inicial y planificación empresarial.",
        BusinessBoxInfoContentEnterpriseItem2: "Estudios de viabilidad.",
        BusinessBoxInfoContentEnterpriseItem3: "Gestión de proyectos.",
        BusinessBoxInfoContentEnterpriseItem4: "Estrategia corporativa.",
        BusinessBoxInfoContentEnterpriseItem5: "Servicios de consultoria empresarial.",
        PrivacyPolicy: "Política de Privacidad",
        PrivacyPolicyBoxInfoContent1: "Flow2Grow está preocupado por su derecho a la privacidad. Cuando visite nuestro sitio web, tenga en cuenta que se recopilan los siguientes datos:",
        PrivacyPolicyBoxInfoContentItem1: "El tipo de navegador utilizado.",
        PrivacyPolicyBoxInfoContentItem2: "Región geográfica en donde se origina la conexión.",
        PrivacyPolicyBoxInfoContentItem3: "Fecha y hora de la conexión.",
        PrivacyPolicyBoxInfoContentItem4: "Páginas visitadas durante la conexión.",
        PrivacyPolicyBoxInfoContentItem5: "Fecha y hora en que se cortó la conexión.",
        PrivacyPolicyBoxInfoContent2: "Esta información se usa únicamente para determinar patrones de tráfico en el sitio. No se recopila información personal en ningún momento. Cualquier correspondencia personal que se origine en este sitio, a través de uno de los enlaces de correo electrónico, será solo para uso interno y se tratará de manera confidencial. No divulgamos información personal a terceros sin consentimiento. Si tiene preguntas o inquietudes sobre nuestra política, comuníquese con nosotros al (+52) 55 6790 6946 durante el horario comercial normal o por correo electrónico a info@flow2grow.com.",
        PrivacyPolicyBoxInfoContent3: "Descargo de Responsabilidad",
        PrivacyPolicyBoxInfoContent4: "Este sitio web es solo para fines informativos y no constituye una descripción completa de nuestros servicios de asesoramiento de inversión ni de ningún rendimiento anterior. Este sitio web no es una solicitud ni una oferta para vender valores o servicios de asesoramiento de inversión, excepto cuando estamos debidamente registrados o exentos de dicho registro. La información a través de este sitio, ya sea cotizaciones de acciones, gráficos, artículos o cualquier otra declaración o declaraciones con respecto al mercado u otra información financiera, se obtiene de fuentes que nosotros y nuestros proveedores consideramos confiables.",
        PrivacyPolicyBoxInfoContent5: "Sin embargo, no garantizamos la puntualidad o exactitud de esta información. Nada en este sitio web debe interpretarse para afirmar o implicar que los resultados pasados ​​son una indicación de rendimiento futuro. No existen garantías, expresas o implícitas, en cuanto a la precisión, integridad o resultados obtenidos de cualquier información publicada en este o cualquier sitio web 'vinculado'.",
        AboutUsTitle: "MÁS ACERCA DE NOSOTROS",
        AboutUsContent: "¡Vamos a crecer!",
        AboutUsContentInfo1: "Flow2Grow es una consultoría financiera con más de 15 años de experiencia en el mercado. Ofrecemos una amplia gama de servicios a nuestros clientes, dentro de los cuales tenemos:",
        AboutUsContentInfoItem1: "Inversiones y planes de ahorro",
        AboutUsContentInfoItem2: "Gestión de activos",
        AboutUsContentInfoItem3: "Jubilación y planificación patrimonial",
        AboutUsContentInfoItem4: "Planificación empresarial, estrategia y orientación.",
        AboutUsContentInfo2: "Nuestra filosofía empresarial es simple: proporcionar el mayor nivel de servicio posible a nuestros clientes. Logramos esto creando y fomentando relaciones positivas, ya que nos apasiona lo que hacemos y el resto fluye de manera natural.",
        AboutUsContentInfo3: "Flow2Grow como empresa se esfuerza por:",
        AboutUsContentInfoItem5: "Mostrar respeto por todos los que nos rodean.",
        AboutUsContentInfoItem6: "Minimizar cualquier impacto negativo en nuestro medio ambiente y maximizar el impacto positivo.",
        AboutUsContentInfoItem7: "Crear oportunidades donde las veamos.",
        AboutUsContentInfoItem8: "Aprender, innovar y mejorar constantemente.",
        AboutUsContentInfoItem9: "Mantenerse humilde y con mente abierta en todo momento.",
        AboutUsTestimonialsTitle: "TESTIMONIOS",
        AboutUsContentTestimonial3: "\"Un consejo fantástico de alguien que se tomó el tiempo para comprender lo que quería y luego darme una variedad de opciones según mis necesidades específicas. Un servicio muy profesional desde el principio y que continúa hasta hoy en día\".",
        AboutUsContentTestimonial2: "\"Estaba buscando a alguien que me ayudara con mis finanzas e inversiones y un buen amigo mío me presentó a Simon. Se tomó su tiempo para entender exactamente cuál era mi situación actual y lo que estaba buscando lograr. Se nos ocurrió un gran plan y ahora estoy muy contenta con los resultados. También considero a Simon un amigo, nos hemos llevado bien desde el principio\"..",
        AboutUsContentTestimonial1: "\"Absolutamente un servicio de primera clase. Recomiendo encarecidamente a Flow2Grow para obtener consejos genuinos, honestos y directos. Extremadamente informado y, sobre todo, realmente agradable de tratar\".",
        AboutUsAuthorTestimonial1: "Dr. A. Lopez",
        AboutUsAuthorTestimonial2: "M. Diletti",
        AboutUsAuthorTestimonial3: "C.Camhaji",
        AboutUsButtonHere: "¡Organicemos una reunión!"
      }
    }
  },
  keySeparator: false,
  interpolation: { escapeValue: false }
});

localStorage.setItem("lang", "en");
const lang = localStorage.getItem("lang");
i18n.changeLanguage(lang);

export default i18n;