import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useState, useEffect } from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import { Header } from '../../components/Header';
import { createUser, getUserById, updateUser } from '../../api/services/UserService'
import { Encrypt, Decrypt } from '../../helpers/Crypto'
import { GeneratePassword } from '../../helpers/RandomPassword'
import {useAuth} from '../../hooks/useAuth'

export const AdminClientEditor = ({ changePage, t }) => {
    let { idUser } = useParams();
    const { LogOut } = useAuth();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [initialInvestment, setInitialInvestment] = useState('');
    const [startDate, setStartDate] = useState('');
    const [termDays, setTermDays] = useState('');
    const [rate, setRate] = useState('');
    const [maturityDate, setMaturityDate] = useState('');
    const [maturityAmount, setMaturityAmount] = useState('');
    const [returnAmount, setReturnAmount] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('')

  useEffect(() => {

    if(idUser){
      
      const getUserData = async() => {

        var response = await getUserById(idUser)
        if(response.status === 200){

          const user = response.data;
          if(user){
            setFirstName(Decrypt(user.firstName))
            setLastName(Decrypt(user.lastName))
            setUserName(Decrypt(user.userName))
            setEmail(Decrypt(user.email))
            setInitialInvestment(user.initialInvestment)
            setStartDate(new Date(user.startDate).toISOString().slice(0,10))
            setTermDays(user.termDays)
            setRate(user.rate)
            setMaturityDate(new Date(user.maturityDate).toISOString().slice(0,10))
            setMaturityAmount(user.maturityAmount)
            setReturnAmount(user.returnAmount)
          }
        }
      }

      getUserData();
    }
    else{
      setPassword(GeneratePassword());
    }

  }, [idUser])

  const onCancel = () => {
    changePage(8)
  }

  const onLogout = () => {
      LogOut();
      changePage(0)
  }

    const onSubmit = (event) => {
      event.preventDefault();
      
    };
    const onCustomerUserName = ({ target }) => {
      setUserName( target.value )
    };
    const onCustomerFirstName = ({ target }) => {
      setFirstName( target.value )
    };
    const onCustomerLastName = ({ target }) => {
      setLastName( target.value )
    };
    const onCustomerEmail = ({ target }) => {
      setEmail( target.value )
    };
    const onCustomerPassword = ({ target }) => {
      setPassword( target.value )
    };
    const onStartDateChange = ({ target }) => {
      setStartDate( target.value );
    };
    const onInitialInvestment = ({ target }) => {
        setInitialInvestment( target.value )
    };
    const onTermDaysChange = ({ target }) => {
      setTermDays( target.value )
    };
    const onRateChange = ({ target }) => {
      setRate( target.value )
    }
    const onMaturitydateChange = ({ target }) => {
      setMaturityDate( target.value )
    }
    const onMaturityAmountChange = ({ target }) => {
      setMaturityAmount( target.value )
    }
    const onReturnChange = ({ target }) => {
      setReturnAmount( target.value )
    }

    const onTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    const onSaveChanges = async() => {
      // console.log("Initial Investment:", initialInvestment);
      // console.log("Start Date:", startDate);
      // console.log("Term Days:", termDays);
      // console.log("Rate:", rate);
      // console.log("Maturity Date:", maturityDate);
      // console.log("Maturity Amount:", maturityAmount);
      // console.log("Return Amount:", returnAmount);

      try
      {
        const user = {
          firstName: Encrypt(firstName),
          lastName: Encrypt(lastName),
          username: Encrypt(userName),
          email: Encrypt(email),
          initialInvestment,
          startDate,
          termDays,
          rate,
          maturityDate,
          maturityAmount,
          returnAmount
        };
        
        if(!idUser){
          user.password = Encrypt(password);
        }
  
        var response = (idUser) ? await updateUser(idUser, user) : await createUser(user);
        if(response.status === 201 || response.status === 200)
        {
          changePage(8)
        }
      }
      catch(e)
      {
        setError('No se pudo crear el usuario')
      }
    }



  return (
    <>
      <div className="admin-logout-container">
        <button className="admin-logout" onClick={ onLogout }>
              Logout
        </button>
      </div>
      <form onSubmit={ onSubmit }>
        <div className="admin-message animate__animated animate__fadeIn">
            <div className="admin-greeting">
              
            
            <input
                type="text"
                name="customerName"
                value={userName}
                onChange={ onCustomerUserName }
                placeholder="Customer Username"
                className="admin-input"
                required
                pattern="^[A-Za-z ]+$"
                onInvalid={(e) => e.target.setCustomValidity('Introduzca un nombre de cliente válido')}
                onInput={(e) => e.target.setCustomValidity('')}
              />
              <div className="line-customer"></div>
              
            </div>

            <div className="admin-greeting">
              
              <input
                type="email"
                name="customerName"
                value={email}
                onChange={ onCustomerEmail }
                placeholder="Customer Email"
                className="admin-input"
                required
              /*  pattern="^[A-Za-z ]+$"
                onInvalid={(e) => e.target.setCustomValidity('Introduzca un nombre de cliente válido')}
                onInput={(e) => e.target.setCustomValidity('')} */
              />
              <div className="line-customer"></div>
            </div>

            {!idUser &&
               <div className="admin-greeting">
              
                <div className="input-group">
                  <input
                  type={showPassword ? "text" : "password"}
                  name="customerName"
                  value={password}
                  onChange={ onCustomerPassword }
                  placeholder="Password"
                  className="admin-input"
                  required
                  /*  pattern="^[A-Za-z ]+$"
                    onInvalid={(e) => e.target.setCustomValidity('Introduzca un nombre de cliente válido')}
                    onInput={(e) => e.target.setCustomValidity('')} */
                  />

                  <button
                    type="button"
                    className="see-password"
                    onClick={ onTogglePassword }>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                  </button>
                </div>
               <div className="line-customer"></div>
             </div> 
            
            }
        </div>

        <div className="admin-message animate__animated animate__fadeIn">
            <div className="admin-greeting">
              
              <input
                type="text"
                name="customerName"
                value={firstName}
                onChange={ onCustomerFirstName }
                placeholder="First name"
                className="admin-input"
                required
              /*  pattern="^[A-Za-z ]+$"
                onInvalid={(e) => e.target.setCustomValidity('Introduzca un nombre de cliente válido')}
                onInput={(e) => e.target.setCustomValidity('')} */
              />
              <div className="line-customer"></div>
            </div>

            <div className="admin-greeting">
              
              <input
                type="text"
                name="customerName"
                value={lastName}
                onChange={ onCustomerLastName }
                placeholder="Last name"
                className="admin-input"
                required
              /*  pattern="^[A-Za-z ]+$"
                onInvalid={(e) => e.target.setCustomValidity('Introduzca un nombre de cliente válido')}
                onInput={(e) => e.target.setCustomValidity('')} */
              />
              <div className="line-customer"></div>
            </div>
        </div>

        <div className="admin-container">
          <div className="admin animate__animated animate__fadeIn">


            <div className="admin-item">
              <div className="admin-title">Initial investment</div>
              <input
                type="number"
                name="initialInvestment"
                value={ initialInvestment }
                onChange={ onInitialInvestment }
                placeholder="$10"
                className="admin-number"
                required
               
                />
            </div>


            <div className="admin-item">
              <div className="admin-title">Start date</div>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={onStartDateChange}
                className="admin-number"
                required
              />
            </div>


            <div className="admin-item">
              <div className="admin-title">Term days (Days)</div>
              <input
                type="number"
                name="termDays"
                value={ termDays }
                onChange={ onTermDaysChange }
                placeholder="365"
                className="admin-number"
                required
              />
            </div>


            <div className="admin-item">
              <div className="admin-title">Rate (%)</div>
              <input
                type="number"
                name="rate"
                value={ rate }
                onChange={ onRateChange }
                placeholder="20%"
                className="admin-number"
                required
              />
            </div>


            <div className="admin-item">
              <div className="admin-title">Maturity date</div>
              <input
                type="date"
                name="maturityDate"
                value={ maturityDate }
                onChange={ onMaturitydateChange }
                placeholder="01.01.24"
                className="admin-number"
                required
              />
            </div>


            <div className="admin-item">
              <div className="admin-title">Maturity amount (MXN)</div>
              <input
                type="number"
                name="maturityAmount"
                value={ maturityAmount }
                onChange={ onMaturityAmountChange }
                placeholder="$12,000.00"
                className="admin-number"
                required
              />
            </div>


            <div className="admin-item">
              <div className="admin-title">Return (MXN)</div>
              <input
                type="number"
                name="returnAmount"
                value={ returnAmount }
                onChange={ onReturnChange }
                placeholder="$2,000.00"
                className="admin-number"
                required
              />
            </div>


          </div>
        </div>

        <div className="admin-buttons">
          <span className='error' >{error}</span>
          <button className="admin-cancel" onClick={onCancel} >CANCEL</button>
          <button className="admin-changes" onClick={ onSaveChanges } type="submit">SAVE CHANGES</button>
        </div>
      </form>

      
    </>
  );
}
