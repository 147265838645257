import { useAuth } from '../../hooks/useAuth'
import { AdminList } from '../AdminList';
import { ClientDashboard } from '../ClientDashboard';
import { useLocation, Navigate } from 'react-router-dom'

export const Dashboard = ({ changePage, t }) => {

    const { user } = useAuth();
    const location = useLocation();

    return user?.roles.includes('Admin') ? (
        <AdminList changePage={changePage} t={t} />
    ) : user?.roles.includes('User') ?  (
        <ClientDashboard changePage={changePage} t={t} />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
}