import React, { useState } from 'react'
import { Header } from '../../components/Header'
import { AdminListGrid } from './AdminListGrid';
import { useAuth } from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export const AdminList = ({ changePage, t }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const { LogOut } = useAuth();
    const navigate = useNavigate();
    
    const onLogout = () => {
        LogOut(changePage);
    }

    if (!isLoggedIn) {
        // Redirigir al usuario a la página de inicio de sesión
    }

    return (
        <>
            <div  className="adminlist-logout-container">
                <button className="adminlist-logout" onClick={ onLogout }>Logout</button>
            </div>
            <div className="adminlist-message animate__animated animate__fadeIn">
                <div className="container-greeting">
                    <h2 className="adminlist-greeting">Clients list</h2>
                </div>
            </div>

            <AdminListGrid changePage={changePage} />

           
        </>
    )
}
