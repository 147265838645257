import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useEffect, useState } from 'react';
import { Header } from '../../../components/Header';
import { Loader } from '../../../components/Loader';
import { useNavigate, Link } from 'react-router-dom';

import { login } from '../../../api/services/AuthService'
import { useAuth } from '../../../hooks/useAuth'
import { Encrypt } from '../../../helpers/Crypto'


export const FormLogin = ({ changePage, t, logOut }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(true);

  const { LogIn } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);

    if(logOut)
    {
      console.log('entro')
      logOut()
    }

  }, []);

  if (loading) {
        return <Loader />;
  } 

  const onSubmit = async (event) => {
    event.preventDefault();

    try
    {
      const emailEncrypt = Encrypt(email);
      const passwordEncrypt = Encrypt(password);

      var response = await login({username: emailEncrypt, password: passwordEncrypt});

      if(response.status === 201)
      {
        const { accessToken } = response.data;
        LogIn(accessToken);
        changePage(8)
      }
    }
    catch(e)
    {
      setError('Inicio de sesión inválido')
    }
  };

  const onTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const onPasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  return (
   <>
     <div className="container">
      <form className="form-login animate__animated animate__fadeIn" onSubmit={onSubmit}>
        <h2 className="form-title">LOGIN</h2>
        <div className="form-container-login">
          
          <div className="input-group">
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onEmailChange}
              required
            /><span className="border"></span>
            <label htmlFor="email">Email</label>
          </div>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={password}
              onChange={onPasswordChange}
              required
            /><span className="border"></span>
            <label htmlFor="password">Password</label>
            <button
                type="button"
                className="see-password"
                onClick={ onTogglePassword }
            >
               {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
            </button>
          </div>
          <span className='error'>{error}</span>

          <button className="button-login" type="submit">
            SEND
          </button>
        </div>
        <p className="paragraph-login">
          ¿You do not have an account?
          <Link onClick={() => changePage(7)} className='link'>
            Sign up
          </Link>
        </p>
      </form>
    </div>
   </>
  );
}