import Crypto from 'crypto-js'

export function Encrypt(plainText){
  const key = Crypto.enc.Base64.parse(process.env.REACT_APP_KEY);
  const iv = Crypto.enc.Base64.parse(process.env.REACT_APP_IV);
  const encryptedText = Crypto.AES.encrypt(plainText, key, { iv }).toString();
  return encryptedText;
}

export function Decrypt(encryptedTxt){
  const key = Crypto.enc.Base64.parse(process.env.REACT_APP_KEY);
  const iv = Crypto.enc.Base64.parse(process.env.REACT_APP_IV);
  return Crypto.AES.decrypt(encryptedTxt, key, {iv}).toString(Crypto.enc.Utf8);
}