import React, { useState, useEffect } from 'react';
import { Header } from '../../components/Header';
import { getUserById, getUserByUsername } from '../../api/services/UserService'
import { useAuth } from '../../hooks/useAuth'
import { Decrypt } from '../../helpers/Crypto';

export const ClientDashboard = ({id}) => {
    const [name, setName] = useState('[name]');
    const { user, LogOut } = useAuth();

    const [initialInvestment, setInitialInvestment] = useState('$0');
    const [startDate, setStartDate] = useState('01.01.23');
    const [termDays, setTermDays] = useState('0');
    const [rate, setRate] = useState('0%');
    const [maturityDate, setMaturityDate] = useState('01.01.01');
    const [maturityAmount, setMaturityAmount] = useState('$0');
    const [returnAmount, setReturnAmount] = useState('$0');


  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    
    const getUserData = async() =>{

      if(user)
      {
        var response = await getUserById(user.sub);
        if(response.status === 200)
        {
          const userDto = response.data;

          setName(Decrypt(userDto.firstName))
          setInitialInvestment(userDto.initialInvestment.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          setStartDate(new Date(userDto.startDate).toLocaleDateString('en-GB'))
          setTermDays(userDto.termDays)
          setRate(`${userDto.rate} %`)
          setMaturityAmount(userDto.maturityAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
          setMaturityDate(new Date(userDto.maturityDate).toLocaleDateString('en-GB'))
          setReturnAmount(userDto.returnAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
        }
      }
    }

    getUserData();

  }, [])
  

  const onLogout = () => {
    LogOut();
  }

  if (!isLoggedIn) {
    // Redirigir al usuario a la página de inicio de sesión
  }



  return (
    <>
     <div className="dashboard-logout-container"> 
       <button className="dashboard-logout" onClick={ onLogout }>Logout</button>
     </div>
        <div className="dashboard-message animate__animated animate__fadeIn">
          <div className="container-greeting">
              <h2 className="dashboard-greeting">Hi { name }!</h2>
              <p className="dashboard-paragraph">This is the summary of your investment</p>
          </div>
            
        </div>

      <div className="dashboard-container">
        <div className="dashboard animate__animated animate__fadeIn">
            <div className="dashboard-item">
                <div className="dashboard-title">Initial investment</div>
                <div className="dashboard-number">{ initialInvestment }</div>
            </div>
            <div className="dashboard-item">
                <div className="dashboard-title">Start date</div>
                <div className="dashboard-number">{ startDate }</div>
            </div>
            <div className="dashboard-item">
                <div className="dashboard-title">Term days (Days)</div>
                <div className="dashboard-number">{ termDays }</div>
            </div>
            <div className="dashboard-item">
                <div className="dashboard-title">Rate (%)</div>
                <div className="dashboard-number">{ rate }</div>
            </div>
            <div className="dashboard-item">
                <div className="dashboard-title">Maturity date</div>
                <div className="dashboard-number">{ maturityDate }</div>
            </div>
            <div className="dashboard-item">
                <div className="dashboard-title">Maturity amount (MXN)</div>
                <div className="dashboard-number">{ maturityAmount }</div>
            </div>
            <div className="dashboard-item">
                <div className="dashboard-title">Return (MXN)</div>
                <div className="dashboard-number">{ returnAmount }</div>
            </div>
        </div>
      </div>
        

      
    </>
  );
};


    