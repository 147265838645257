import logo from '../images/svg/logo.svg';

export const Loader = () => {
    return (
        <div className="loading ">
             <img src={logo} alt="Logo" className="home-logo-loader animate__animated animate__fadeInUp"/>
        </div>
    );
}

